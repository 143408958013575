import React from "react";

function More(props) {
  return (
    <div>
      <h1>More</h1>
    </div>
  );
}

export default More;
