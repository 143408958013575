import React from "react";
import { NavLink } from "react-router-dom";

function Main() {
  return (
    <>
      <p className="title">Hisob-Kitob</p>
      <div className="buttons">
        <NavLink to="/partners">
          <button>Hamkorlar</button>
        </NavLink>
        <NavLink to="/debtors">
          <button>Qarzdorlar</button>
        </NavLink>
        <NavLink to="/add-partners">
          <button>Hamkor Qoshish</button>
        </NavLink>
        <NavLink to="/add-debtors">
          <button>Qarzdor Qoshish</button>
        </NavLink>
      </div>
    </>
  );
}

export default Main;
