import React from "react";
import "./style/Main.css";
import { Routes, Route } from "react-router-dom";
import Main from "./Pages/Main";
import Partners from "./Pages/Partners";
import Debtors from "./Pages/Debtors";
import AddP from "./Pages/AddP";
import AddD from "./Pages/AddD";
import More from "./Pages/More";

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/partners" element={<Partners />} />
        <Route path="/debtors" element={<Debtors />} />
        <Route path="/add-partners" element={<AddP />} />
        <Route path="/add-debtors" element={<AddD />} />
        <Route path="/more" element={<More />} />
      </Routes>
    </div>
  );
}

export default App;
