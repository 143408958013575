import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";

function AddD(props) {
  const [data, setData] = useState({
    name: "",
    number: "",
    address: "",
  });
  const [formErrors, setFormErrors] = useState({});

  const onChangeHandler = (e) => {
    const newdata = { ...data };
    newdata[e.target.name] = e.target.value;
    setData(newdata);
  };
  const base_url = require("../API.json");
  const url = new URL(base_url.url + "/api/partners/new");

  const validate = (values) => {
    const errors = {};
    if (!values.name) {
      errors.name = "Nom kiriting";
    }
    if (!values.number) {
      errors.number = "Telefon raqam kiriting";
    }
    return errors;
  };

  const [result, setResult] = useState("");
  const navigate = useNavigate();

  function Send(e) {
    e.preventDefault();

    setFormErrors(validate(data));

    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
    };

    let body = {
      name: data.name,
      phone_number: data.number,
      address: data.address,
      type: "debtor",
    };

    fetch(url, {
      method: "POST",
      headers,
      body: JSON.stringify(body),
    })
      .then((response) => response.json())
      .then((res) => {
        if (res.result === "Phone number already exists") {
          setResult("Bunday raqam mavjud");
        } else if (res.result === "Partner created successfully") {
          navigate("/");
        }
      });
  }

  return (
    <div>
      <div className="name_back">
        <p className="page_title">Qarzdorlar qo'shish</p>
        <NavLink to="/">
          <button>Orqaga qaytish</button>
        </NavLink>
      </div>
      <div>
        <form onSubmit={Send} className="Add_p" action="">
          <div>
            <input
              type="text"
              value={data.name}
              name="name"
              onChange={onChangeHandler}
              placeholder="Nomi"
            />
            <p className="error">{formErrors.name}</p>
          </div>
          <div>
            <input
              type="text"
              onChange={onChangeHandler}
              placeholder="Telefon raqam"
              value={data.number}
              name="number"
            />
            <p className="error">{formErrors.number}</p>
          </div>
          <input
            type="text"
            value={data.address}
            name="address"
            onChange={onChangeHandler}
            placeholder="Manzil"
          />
          <div className="res_but">
            <p className="result_error">{result}</p>
            <button type="submit">Jo'natish</button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default AddD;
